export const patientSTGT: any = {
    mainMenu: "Main Navigational Menu Consumer GCSO",
    footerMenu: "Footer Navigation Menu Consumer GCSO",
    banner: "Banner - HTML - Steps Genetic Testing - DTC - Canada",
    footer: "Footer DTC HTML Canada",
    exitPopup: "Retina Canada Exit Popup HTML",
    hcpSwitcher: "HCP Switcher Modal Pop Up",
    topNav: "Top Navigation Menu Retina Canada HTML DTC",
    footerCallout: "Navigational Callout - Steps To Genetic Testing",
    siteLogo: "Site logos DTC",
    geneticTestReveal: "Genetic Test Can Reveal - Steps To Genetic Testing",
    testConfirms: "If a Test Confirms - Steps To Genetic Testing ",
    accordion: "Accordion Section - Steps To Genetic Testing New",
    slider: "Slider Section - Step to Genetic Testing",
    stepContent: "Step",
    redirectLangUrl: {
      "redirectUrl": {
        "en": "/steps-to-genetic-testing",
        "fr": "/fr/steps-to-genetic-testing"
      }
    },
    backToTopGTM: "Back to top - steps to genetic testing",
    sliderAttrs: {
      sliderGTMElements: {
        'iconGTMs': [
          {
            'className': 'gtm-accordion',
            'aria-label': '1 Schedule an exam'
          },
          {
            'className': 'gtm-accordion',
            'aria-label': '2 Request a referral'
          },
          {
            'className': 'gtm-accordion',
            'aria-label': '3 It takes time'
          },
          {
            'className': 'gtm-accordion',
            'aria-label': '4 Meet and undergo testing'
          },
          {
            'className': 'gtm-accordion',
            'aria-label': '5 Discuss the results'
          },
        ],
        'prevBtnGTMs': [
          {
            'aria-label': 'Go to step 1',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 2',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 3',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 4',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 5',
            'className': ' '
          },
        ],
        'nextBtnGTMs': [
          {
            'aria-label': 'Go to step 1',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 2',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 3',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 4',
            'className': ' '
          },
          {
            'aria-label': 'Go to step 5',
            'className': ' '
          },
        ]
      }
    },
    footerClassName: "steps-to-genetic-testing-footer"
  }
