import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';

export const FooterCard = styled.div`
  .steptogenetic-footer--block {
    padding: 0 88px;
    display: flex;

		@media ${device.laptopMedium} {
			display: block;
			padding: 0;
			padding: 0 42px;
		}

    .footer-block--row {
      display: flex;

			@media ${device.laptopMedium} {
				display: block;
			}

      .footer-inner-block {
        text-align: center;
        position: relative;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
        padding: 32px 24px 120px;
        margin: 0 16px;
        width: calc(50% - 15px);
				z-index: 992;

				.media--image {
					img {
						padding: 8px 0;
						width: 12%;
						margin: auto;
						text-align: center;
						display: block;
						height: 100px;

						@media ${device.ipadLandscape} {
							width: 100%;
							max-width: 33px;
						}
					}
				}

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 16px;
					padding-right: 16px;
					padding-bottom: 20px;
					margin-bottom: 20px;
				}

        &:first-child {
          margin-left: 0;

				&:before {
				background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
				}
        }
        &:last-child {
					.o-common-title {
						&:before {
							width: 90px;
						}
					}
					@media ${device.laptopMedium} {
						margin-left: 0;
						margin-bottom: 0;
					}
					&:before {
						background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
						}
        }
        &:before {
          content: "";
          width: 100%;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px 5px 0 0;
        }

        .o-common-title {
          font-weight: 700;
          font-size: 2.7rem;
          line-height: 3.8rem;
          color: #8000BE;
          text-align: center;
					margin-bottom: 30px;

					@media ${device.laptopMedium} {
						font-weight: 600;
						font-size: 20px;
						line-height: 24px;
					}
        }
        .text-para-wrap {
          padding: 10px 5px 30px 5px;
          font-size: 2.2rem;
          line-height: 3.2rem;
          color: #000000;
          text-align: center;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.2rem;
            padding: 10px 0 20px;
          }
        }
        span.text-button-wrap {
          a {
            box-shadow: 0px 3px 4px #d6d6d6;
            position: absolute;
            left: 25px;
            right: 25px;
            width: 90%;
            bottom: 45px;
            text-align: center;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            cursor: pointer;
            letter-spacing: 0;
            border-radius: 6px;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            padding: 16px 22px;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-decoration: none;
            z-index: 1;

						@media ${device.laptopMedium} {
							position: revert;
							width: 100%;
    					margin-top: 15px;

						}

						@media ${device.ipadLandscape} {
							padding-left: 15px;
    					padding-right: 15px;
							padding-top: 10px;
							padding-bottom: 10px;

						}

						@media ${device.ipadLandscapemin} {
							font-size: 2.2rem;
						}


            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              position: absolute;
              left: 25px;
              right: 25px;
              width: 90%;
              bottom: 45px;
              text-align: center;
              border: 1px solid #fff;

              @media ${device.laptopMedium} {
							position: revert;
              }
            }

						&:after {
							content: "";
							display: inline-block;
							width: 16px;
							height: 14px;
							position: relative;
							top: 2px;


							@media ${device.laptopPros} {
								display: block;
    						margin-top: 8px !important;
								margin: auto;
							}
              @media ${device.ipadLandscapemin}{
                margin-left: 8px;
              }
              @media ${device.desktopStart}{
                top: 1px;
              }
						}
          }
        }
      }
    }
  }
`;
export const StepsToGenetic = styled.div`
  .carouselHead {
    padding-top: 41px;
    padding-bottom: 41px;

		@media ${device.laptopMedium} {
			padding: 32px 0 0 0;
		}

    p,h2 {
      color: #000000;
      font-weight: 700;
      text-align: center;
      font-size: 4.2rem;
      line-height: 5rem;

			@media ${device.laptopMedium} {
				padding: 2px 20px 20px 20px;
				font-size: 2.5rem;
    			line-height: 3rem;
				margin-bottom: 0;
			}
    }
  }

  .carouselSlides {

		.o-container {
			@media ${device.laptopMedium} {
				padding-left: 27px;
    		padding-right: 27px;
			}
		}

		.btn-wrapper-slider {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.button {
				font-size: 2.2rem;
					font-weight: 500;
					line-height: 2.6rem;
					color: #fff;
					box-shadow: 0px 3px 4px #d6d6d6;
					margin: 5px 10px;
					border: 1px solid #fff;

					&:after {
						margin-left: 15px;
					}

					&:hover {
						transition: ease-in 0.5s;
						font-weight: 700;
						&:after {
							margin-left: 19px;
						}
					}
				}
			}

			.generic-slider-no-image {
				position: relative;
				padding-top: 0;

				.swiper-pagination {
					bottom: unset;
					top: 20px;
					background-color: #00FFD9;
					height: 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					max-width: 968px;
					margin: auto;
					text-align: center;
					left: 0;
					right: 0;
					position: absolute;
					transition: 0.3s opacity;
					z-index: 10;

				@media ${device.laptopMedium} {
					width: 100%;
					top: 44px;
				}

				.swipper-texts1::after {
					content: "Schedule an exam";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts2::after {
					content: "Request a referral";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts3::after {
					content: "It takes time";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts4::after {
					content: "Meet and undergo testing";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts5::after {
					content: "Discuss the results";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}

        .swiper-pagination-bullet {
          width: 68px;
          height: 68px;
          margin: 0px;
          opacity: 1;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00FFD9;
          cursor: pointer;
          border-radius: 100%;
          font-weight: 700;
          line-height: 4.7rem;
          font-size: 3.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #7323CA;

					@media ${device.laptopMedium} {
						width: 44px;
          	height: 44px;
						font-size: 2.7rem;
					}

          &.active {
            background-color: #7323CA;
            color: #fff;

						&:before {
							color: #fff;
						}
          }

          &:before {
            font-weight: 700;
            line-height: 4.7rem;
            font-size: 3.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #7323CA;
          }

          &:after {
            line-height: 3.2rem;
            font-size: 2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #8000BE;
            position: absolute;
            width: 270px;
            top: 80px;
						font-weight: bold;
						font-weight: 500;

						@media ${device.ipadLandscapemin} and ${device.midDesktopMax}{
							width: 200px;
						}
          }
        }
				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}
      }
    }
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
			width: 137px;
			height: 54px;
			background-color: #7323CA;
			position: relative;
			display: table-cell;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;
			margin: 0 10px;
			padding: 0 0;

			@media ${device.laptopMedium} {
				width: 137px;
			}

			&.back-btn {
				&:hover {
					transition: ease-in 0.5s;
					font-weight: 700;
					&:before {
						margin-right: 19px;
					}
				}
				&:before {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-right: 15px;
					transform: rotate(-180deg);
					position: relative;
					top: 1px;
				}
			}

			&.forward-btn {
				&:after {
					content: "";
					display: inline-block;
					margin-left: 15px;
					position: relative;
					top: 2px;
					width: 17px;
					height: 17px;
				}
			}
		}
  }
	.slick-slider {

		@media ${device.laptopMedium} {
			margin-top: 0;
		}

		.item {
			max-width: 880px;
			margin: auto;

			.item__content {
				display: flex;
				margin: auto;
				margin-top: 185px;

				@media ${device.laptopMedium} {
					margin-top: 50px;
					display: block;
    			width: 100%;
					padding: 0 2.7rem;
				}

				div.item__icon {
					margin-right: 28px;
					min-width: 80px;
					@media ${device.laptopMedium} {
						margin: auto;
					}

					.media {
						img {
							@media ${device.laptopMedium} {
								width: 41px;
								margin: 0 auto;
							}
						}
					}
				}

				div.form-card-heading {
					font-size: 2.2rem;
					color: #8000BE;
					margin-bottom: 30px;
					display: none;
					font-weight: bold;

					@media ${device.laptopMedium} {
						margin: 94px auto 20px auto;
						display: table;
						font-weight: 400;
						font-size: 1.8rem;
						line-height: 2.7rem;
					}

					.item__icon {
						img {
							width: 64px;
							height: auto;
							margin: auto;
    					display: block;

							@media ${device.laptopMedium} {
								width: 41px;
							}
						}
					}
				}
				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
				}

				p.wizard-paragraph {

					span {
						font-weight: bold;
					}

					@media ${device.laptopMedium} {
						text-align: center;
    				font-size: 1.8rem;
						line-height: 2.2rem;
    				padding-top: 12px;
						max-width: 634px;
						margin: auto;
					}
				}
			}
		}
	}
`;
export const GeneticTesting = styled.div`

	.o-section--cover-bg {
		position: relative;
		padding-top: 100px;

		@media ${device.laptopMedium} {
			padding-top: 28px;
		}

		&::before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 182px;
			left: 0;
			height: calc(100% - 240px);
			width: 100%;
		}

	}

	.result-points {
		.result-step-component {
			max-width: 1168px;

			.counselor-point {
				padding: 5rem 50px 0;

				@media ${device.laptopMedium} {
					padding: 2.2rem 4.2rem 0;
				}

				ul {
					list-style: none;
					padding: 0;
				}
			}

			.image-points-section {
				padding: 93px 0 64px;

				h2 {
					line-height: 5rem;
					padding: 0;
					width: 100%;
    				margin: auto;
				}

				h2 {
					margin-top: 50px;
				}

				.o-header--h2 {
					margin-top: 0;
				}

				.o-inner {
					padding-left: 100px;
					padding-right: 100px;
				}

				@media ${device.laptopMedium} {
					padding: 27px 0 24px;
					.o-inner {
						padding-left: 0px;
						padding-right: 0px;
					}

					h2 {
						line-height: 3rem;
						font-size: 2.5rem;
    					padding: 0 30px;
						margin: auto;
						margin-top: 50px;
					}
				}
			}

			.image-points-section {
				.left-img {
					width: 52.5%;
					text-align: center;

					img {
						max-width: 100%;
					}

					@media ${device.ipadLandscape} {
						width: 100%;
    					margin: 0;
					}
				}

			}

			.o-internal--h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;
			}
		}

		.o-column--full {
			width: 100%;
		}

		.bg-color-component {
			background-color: #F7F7F7;
			-webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			-moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
			position: relative;

			&::before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 16px;
				left: 0;
				right: 0;
			}
		}

		.listing-btm-section {
			padding: 40px 0 0 100px;
			display: flex;
			flex-wrap: wrap;

			.listing-btm-section__content {
				width: 47.5%;
   			padding-right: 48px;

				p {
					width: 100%;
					float: none;
					padding: 0;
					font-size: 2.7rem;
					line-height: 3.2rem;
				}

				.o-paragraph {
					font-weight: 500;
					font-family: "Gotham", Arial, Helvetica, sans-serif;
				}

				.results-points-content {
					font-size: 2.2rem;

					@media ${device.laptopMedium} {
							font-size: 1.8rem;
							line-height: 2.2rem;
					}
				}

				span.wizard-paragraph-spacing {
					padding-top: 20px !important;
					font-weight: 400;
					display: block;

				}
			}

			@media ${device.laptopMedium} {
				padding: 0px;

				.wizard-paragraph-spacing {
					padding-top: 12px !important;
					display: initial;
				}

				.results-points-content {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}

				.listing-btm-section__content {
					p {
						font-size: 2rem;
						padding: 12px 40px 20px 40px !important;
						display: grid;
						line-height: 2.4rem;
						text-align: center;
					}
				}

				.listing-btm-section__content {
					width: 100%;
					padding-right: 0;
				}
			}
		}

		.counselor-block {
			p {
				float: right;
				width: 50%;
				margin: initial;
				align-items: flex-end;
				justify-content: end;
				padding: 20px 0 0 20px;
				color: #000000;
			}
		}

		.counselor-point {
			width: 100%;
			margin: auto;
			padding-top: 15rem;

			li {
				color: #000000;
				padding: 16px 0 16px 60px;
				position: relative;

				&::before {
					content: "";
					background-size: 95%;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
					top: 12px;
					background-color: #8000BE;
					color: #ffffff;
					font-size: 16px;
					border-radius: 50%;
					width: 40px;
					height: 40px;
					text-align: center;
				}

				@media ${device.laptopMedium} {
					padding: 6px 0 6px 42px;
					font-size: 1.8rem;
    				line-height: 2.2rem;

					&::before {
						top: 4px;
    				left: 0;
						bottom: initial;
						font-size: 2rem;
						margin: auto;
						width: 26px;
						height: 26px;
					}
				}
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
			}
		}

		@media ${device.laptopMedium} {

			.counselor-point {
				.o-paragraph {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}
			}
			padding-left: 27px;
    		padding-right: 27px;
		}
	}

	// footer {
	// 	.row {
	// 		padding: 0;
	// 	}
	// }

  // .footer{
  //   margin: -8rem auto 0;
  //   @media ${device.ipadLandscapemin}{
  //     margin: -13rem auto 0;
  //   }
  //   .footercontainer{
  //     padding: 0;
  //   }
  //   .row{
  //     .show-hide{
  //       @media ${device.minideskend}{
  //         padding: 18rem 0 3rem;
  //       }
  //       @media ${device.laptop}{
  //         padding: 20rem 0 0;
  //       }
  //     }
  //   }
  // }


	.pagewrapper {
		&:before {
			height: calc(100% + 150px);
      @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
        top: -69px;
      }
		}
	}

	.genetic-testing {
		overflow: hidden;
    .clinical-trials-banner{
      &:after{
        @media ${device.tabletMax}{
          top: 8px;
        }
        top: -5px;
      }
      .internal-banner{
        .internal-banner__contant{
          .o-container{
            @media ${device.mobileS} and ${device.mobilemax} {
              margin: 0 0.9rem !important;
              padding: 0;
            }
            @media ${device.laptopPros} {
              margin: 0 2rem;
              padding: 0;
            }
            h1{
              @media ${device.mobileMedium}{
                line-height: 38px;
              }
              @media ${device.ipadLandscapemin}{
                max-width: 70%;
              }
              @media ${device.desktopStart}{
                padding:0;
              }
            }
          }
        }
      }
    }
		.accordion-section {
			padding-top: 84px;
			padding-bottom: 0;

			.panel-default > .panel-heading + .panel-collapse > .panel-body {
				border-top-color: #eeeeee;
				background-color: #f7f7f7;
				padding-left: 27px;
				padding-bottom: 20px;
				padding-right: 30px;

				@media ${device.ipadLandscape} {
					padding-left: 0;
				}
			}

			@media ${device.laptopMedium} {
				padding-top: 48px;
				padding-bottom: 0px;
			  }

			.o-container {
				@media ${device.laptopMedium} {
					padding-left: 0;
					padding-right: 0;
				}
			}

			h3, h2 {
				text-align: center;
				color: #000000;
				max-width: 700px;
				margin: auto;
				padding-bottom: 40px;

				@media ${device.laptopMedium} {
				  font-size: 2.5rem;
				  line-height: 3rem;
				  font-weight: 600;
				}
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
				text-align: center;

				@media ${device.laptopMedium} {
				  font-size: 2.5rem;
				  line-height: 3rem;
				}
			}

			p {
				text-align: center;
				color: rgb(0, 0, 0);
				max-width: 820px;
				margin: auto;
				font-weight: 400;
			}

			p.inherited-disclaimer {
				font-size: 2.2rem;
				line-height: 3.2rem;
				max-width: 90%;
        font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
				margin-bottom: 64px;
			}

			.accordion-link-text {
				text-align: right;
				max-width: 100%;
				margin: auto;

				@media ${device.laptopMedium} {
				  text-align: center;
				  padding: 15px 20px;
				}

				a {
				  color: #000000;
				  font-weight: 700;
				  padding-right: 32px;
				  font-size: 2.2rem;
				  line-height: 3.2rem;

				  	&:last-child {
						padding-right: 0px;
						text-decoration: none;
					}

					&:hover {
					  color: #8000BE;
					  text-decoration: underline !important;
					  text-underline-offset: 3px;
					  transition: all 0.3s ease;
					}

					@media ${device.tabletMax} {
						font-size: 1.8rem;
						line-height: 2.6rem;
					}
				  }

				  .active {
					color: #8000BE;
					text-decoration: underline;
					text-underline-offset: 3px;
					transition: all 0.3s ease;
				  }

				  @media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
				  }
				}
				a.active {
				  text-decoration: underline;
				  text-underline-offset: 3px;
				  transition: all 0.3s ease 0s;
				  color: #8000BE;
				}
			  }

			  .panel-group {

				.panel-default > .panel-heading {
					padding: 0;
					border-radius: 0;
					color: #212121;
					background-color: #fafafa;
					border-color: #eeeeee;
				}

				.collapse:not(.show) {
					display: none;
				}

				.panel {
					border-radius: 0;
					margin: 20px 0;
					box-shadow: none;
					box-shadow: 0 4px 6px #cecece;
					border: 1px solid #8000BE;


				  .panel-heading {
            height: 91px;
            @media ${device.ipadLandscapemin} {
              height: 99px;
            }
					  .panel-title {
              display: flex;
              align-items: center;
              position: relative;
              padding: 30px 12px 30px 16px;
              margin: 0px;
              @media ${device.ipadLandscapemin} {
                padding: 30px 15px 30px 27px;
              }
              @media ${device.desktopsignup} {
                padding: 30px 34px 30px 27px;
              }
              .accordion-title-button {
                background: transparent;
                border: transparent;
                margin-top: -10px;
                text-align: left;
                width: 100%;
                font-weight: 700;
                line-height: 26px;
                padding: 0;
                @media ${device.ipadLandscapemin} {
                  line-height: 34px;
                }
                @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                  margin-right: 0;
                }
                &:after {
                  background-size: 40px;
                  width: 40px;
                  height: 40px;
                  background-repeat: no-repeat;
                  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4);
                  border-radius: 50%;
                  @media ${device.ipadLandscapemin} {
                    background-size: 35px !important;
                    width: 35px;
                    height: 35px;
                  }
                  // @media (min-width: 769px) and (max-width: 991px) {
                  //   margin-right: -15px;
                  //   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
                  // }
                  @media ${device.smalldeskstrt} {
                    margin-right: -17px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
                  }
                  @media ${device.desktopsignup} {
                    margin-right: -19px;
                  }
                }
                span {
                    font-weight: 500;
                }
              }
					  .accordion-icon {
              width: 37px;
              height: 37px;
              margin-right: 27px;
              font-size: 42px;
              margin-top: 4px;
              border-color: transparent;
              background: transparent;
              justify-content: center;
              display: flex;
              align-items: center;
              color: rgb(128, 0, 190);
              display: none;

              @media ${device.laptopMedium} {
                width: 25px;
                margin-right: 16px;
                font-size: 35px;
              }

						&:before {
						  content: " ";
						  height: 29px;
						  width: 28px;
						  margin-left: -12px;
							background-size: 20px;

							@media ${device.ipadLandscapemin} {
								background-size: 22px;
							}
						}
					  }

						.scientific {
							color: rgb(128, 0, 190);
							line-height: 3.4rem;
							font-size: 22px;
							text-decoration: none;

							@media ${device.laptopMedium} {
								width: 80%;
								font-size: 20px;
								line-height: 2.6rem;
							}
					  }
					  .o-text-title {
						width: 100%;

						@media ${device.tabletMax} {
						  width: 80%;
						}
					  }

					  .glyphicon {
							position: absolute;
							right: 15px;
							font-size: 0;
							width: 35px;
							height: 35px;
							background-color: rgb(128,0,190);
							border-radius: 50%;
							box-shadow: rgb(0 0 0 / 40%) 0px 4px 6px;

							@media ${device.laptopMedium} {
								right: 12px;
								width: 40px;
								height: 40px;
							}
					  }

					  .glyphicon-plus {
						&:before {
						  content: ' ';
						  height: 35px;
						  width: 35px;
						  display: block;
							background-size: 35px;

							@media ${device.laptopMedium} {
                background-size: 40px;
                width: 40px;
                height: 40px;
              }
						}
					  }
					  .glyphicon-minus {
						&:before {
						  content: ' ';
						  height: 35px;
						  width: 35px;
						  display: block;
							background-size: 35px;

							@media ${device.laptopMedium} {
                background-size: 40px;
                width: 40px;
                height: 40px;
              }
						}
					  }
					}
				  }
				  .panel-body {
						p.o-paragraph {
							color: rgb(48, 0, 100);
							text-align: left;
							margin: auto;
							font-weight: 400;
							font-size: 2.2rem;
							line-height: 3.2rem;

							@media ${device.laptopMedium} {
							padding-top: 20px;
							font-size: 1.8rem;
							line-height: 2.6rem;
							padding-left: 30px;
							}

							@media ${device.ipadLandscape} {
								padding-left: 16px;
							}
						}
						.listing-section {
							margin: 0px;
							width: 100%;
							text-align: left;
							display: table;
							list-style: none;
							padding-left: 0;
							@media ${device.ipadLandscape} {
								padding-left: 16px;
							}

							li {
							display: block;
							padding: 8px 0px 8px 25px;
							color: rgb(0, 0, 0);
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
							text-align: left;
							font-size: 1.8rem;
							font-weight: 500;
							line-height: 2.6rem;
							position: relative;
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 3.2rem;
              }
							&:before {
								top: 19px;
								content: "";
								position: absolute;
								background-color: rgb(128, 0, 190);
								width: 8px;
								height: 8px;
								margin: auto;
								left: 0px;
							}
					  }
					}

					.listing-head {
						.o-columns--three {
							margin-bottom: 10px;

							.o-paragraph {
								margin-left: 0;
								position: relative;
								padding-left: 25px;
                font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

								@media ${device.laptopMedium} {
									margin-left: 20px;
								}

								&:before {
									top: 13px;
									content: "";
									position: absolute;
									background-color: rgb(128,0,190);
									width: 8px;
									height: 8px;
									margin: auto;
									left: 0px;

									@media ${device.laptopMedium} {
										top: 27px;
									}
								}
							}
						}
					}

					.list-para-accord,
					li {
						p {
							text-align: left;
							font-size: 1.8rem;
							max-width: 100%;
						}
						a {
							color: #8000BE;
						}
						@media ${device.ipadLandscape} {
							padding-left: 16px;
						}
					}

					.listing-second {
						padding-bottom: 10px;

						.o-columns--three {
							.o-paragraph {
								margin-left: 0;
							}
						}
					}

					.listing-main {
					  display: block;
					  padding-bottom: 10px;

					  @media ${device.laptopMedium} {
							display: block;
							padding-left: 0;
					  }

					  .o-columns--three {
							width: 100%;

							@media ${device.laptopMedium} {
								width: 100%;
							}

							.listing-section {
								@media ${device.laptopMedium} {
									padding-left: 0;
								}

								li {
									padding-left: 27px;
									margin-left: 0;

									@media ${device.laptopMedium} {
										margin-left: 20px;
										padding-left: 25px;
									}

									&:before {
										@media ${device.laptopMedium} {
											top: 16px;
										}
									}
								}
							}

							&.no-bullet {
								.listing-section {
									li {
										padding-left: 27px;
										margin-left: 25px;

										@media ${device.laptopMedium} {
											margin-left: 48px;
										}

										&:before {
											display: none;
										}
									}
								}

								.o-paragraph {
									position: relative;
									margin-left: 25px;
									padding-left: 25px;

									@media ${device.laptopMedium} {
										margin-left: 50px;
									}

									&:before {
										content: "";
										position: absolute;
										background-color: #8000BE;
										width: 8px;
										height: 8px;
										-webkit-transform: translateY(-50%);
										-ms-transform: translateY(-50%);
										transform: translateY(-50%);
										margin: auto;
										left: 0;
										border-radius: 50%;
										top: 15px;

										@media ${device.laptopMedium} {
											top: 32px;
										}
									}
								}
							}
					  }
					}

					div.list-para-accord {
						padding-left: 0;

						p {
							color: rgb(48, 0, 100);
							text-align: left;
							margin: auto;
							font-weight: 400;
							font-size: 2.2rem;
							line-height: 3.2rem;
							position: relative;
							margin-left: 0;
							position: relative;
							padding-left: 25px;
              font-family: "Gotham-Book", Arial, Helvetica, sans-serif;


							@media ${device.laptopMedium} {
								padding-top: 20px;
								font-size: 1.8rem;
								line-height: 2.6rem;
								margin-left: 20px;
							}

							&:before {
								top: 13px;
								content: "";
								position: absolute;
								background-color: rgb(128,0,190);
								width: 8px;
								height: 8px;
								margin: auto;
								left: 0px;

								@media ${device.laptopMedium} {
									top: 27px;
								}
							}
						}
					}
				}
			}
		}
	}

  .step-to-genetic-testing-more-answers {
    h2 {
      @media ${device.laptopMedium} {
        max-width: 100%;
        margin: auto;
      }
    }
    .o-paragraph {
      text-align: center;
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 3.2rem;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
          line-height: 2.2rem;
      }
    }

    .o-inner {
      padding-left: 100px;
      padding-right: 100px;

      @media ${device.laptopMedium} {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .image-text-card {
      padding-top: 110px;
      padding-bottom: 78px;

      h2 {
        color: #000000;
      }

      .box-bottom-paragraph {
        @media ${device.laptopMedium} {
          padding: 20px 0 0 0;
          }
      }

      p {
        max-width: 768px;
          padding: 40px 0 42px 0;
        color: #000000;
          text-align: center;
        margin: auto;

        @media ${device.laptopMedium} {
          padding: 12px 0 5px 0;
        }

      }

      @media ${device.laptopMedium} {
        padding-top: 44px;
          padding-bottom: 44px;
      }
    }

    .o-internal--h2 {
      font-size: 4.2rem;
      font-weight: 700;
      line-height: 5rem;
      color: #000000;
      text-align: center;
      font-family: "Gotham", Arial, Helvetica, sans-serif;

      @media ${device.laptopMedium} {
        font-size: 2.5rem;
        line-height: 3rem;
      }
    }

    .box-card {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 768px;
      margin: auto;

      @media ${device.laptopMedium} {
        display: block;
        }

      .box-inner-card {
        background-color: #300064;
        width: 40%;
        padding: 32px 32px 28px 32px;
        margin: auto 16px;
        flex: 1;
        min-height: 233px;

        @media ${device.laptopMedium} {
          padding: 24px 42px 20px 42px;
          min-height: auto;
            height: 100%;
          width: 100%;
          margin: 0;
          margin-top: 15px;
        }

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(2) {
          margin-right: 0;
        }

        &:nth-child(2) {
          img {
            width: 68px;
            @media ${device.laptopMedium} {
              width: 40px;
            }
          }
        }

        p {
          padding: 10px 0 0;
          color: #fff;
        }

        img {
          margin: auto;
          text-align: center;
          display: block;
          max-width: 100%;
          vertical-align: middle;

          @media ${device.laptopMedium} {
            width: 28px;
          }
        }

      }
    }
  }
`;
